import React, { Suspense, useEffect, useState } from "react";
import AppRoute from "./routes/app/app.route";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import UpdateContext from "./context/update";
import { MagicSpinner } from "react-spinners-kit";
import "./App.less";
import { Breakpoint } from "react-socks";
import Sidebar from "../src/components/LandingPage/Sidebar";
import { OnlineStatusProvider } from "./components/useOnlineStatus";
import { getItem } from "./SecureStorage";

const Header = React.lazy(() => import("./components/Header/Header"));

const EnerparcHeader = React.lazy(() =>
  import("./components/Header/EnerparcHeader")
);
const GanttStageRoute = React.lazy(() => import("./routes/gantt-stage.route"));
const DeleteRouter = React.lazy(() => import("./routes/delete.route"));
const UpdateRouter = React.lazy(() => import("./routes/update.route"));
const DataDashboardRoute = React.lazy(() =>
  import("./routes/data-dashboard-login.route")
);

function App() {
  const [updateStatus, setUpdateStatus] = useState(false);
  const [updateTicketStatus, setUpdateTicketStatus] = useState(false);
  const [updateUploadStatus, setUpdateUploadStatus] = useState(false);
  const [sidebarFlag, setSidebarFlag] = useState(false);

  let userData;

  if (getItem("userData")) {
    userData = JSON.parse(getItem("userData"));
  }

  const closeSidebar = () => {
    setSidebarFlag(true);
  };

  const resetSidebarFlag = () => {
    setSidebarFlag(false);
  };

  const updateStatusHandler = () => {
    setUpdateStatus((prevState) => !prevState);
  };

  const updateTicketStatusHandler = () => {
    setUpdateTicketStatus((prevState) => !prevState);
  };

  const updateUploadStatusHandler = () => {
    setUpdateUploadStatus((prevState) => !prevState);
  };

  return (
    <Suspense
      fallback={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <MagicSpinner size={100} color="#3d87f1" loading={true} />
        </div>
      }
    >
      <OnlineStatusProvider>
        <UpdateContext.Provider
          value={{
            updateStatus: updateStatus,
            updateTicketStatus: updateTicketStatus,
            updateUploadStatus: updateUploadStatus,
            updateStatusHandler: updateStatusHandler,
            updateTicketStatusHandler: updateTicketStatusHandler,
            updateUploadStatusHandler: updateUploadStatusHandler,
          }}
        >
          <DeleteRouter />
          <UpdateRouter />
          <BrowserRouter>
            <Breakpoint medium up style={{ position: "sticky", top: "0" }}>
              {userData?.companyName === "Enerparc Energy Pvt Ltd" ? (
                <EnerparcHeader />
              ) : (
                <Header selectedLogin={"Project Management Tool"} />
              )}
            </Breakpoint>
            <Breakpoint small down>
              <Sidebar
                style={{ background: "#000000" }}
                resetSidebarFlag={sidebarFlag ? resetSidebarFlag : null}
                closeSidebarAction={sidebarFlag ? sidebarFlag : null}
              />
            </Breakpoint>
            <div onClick={closeSidebar} className="app">
              <Switch>
                <Route path="/data-dashboard" component={DataDashboardRoute} />
                <Route
                  path="/"
                  render={() => (
                    <AppRoute selectedLogin={"Project Management Tool"} />
                  )}
                />
              </Switch>
            </div>
          </BrowserRouter>
        </UpdateContext.Provider>
      </OnlineStatusProvider>
    </Suspense>
  );
}

export default App;
