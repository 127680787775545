import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
  useHistory,
} from "react-router-dom";
import { clearItems, getItem, setItem } from "../../SecureStorage";
import "./app.route.style.less";
import * as actionCreators from "../../actions/auth-action/index";
import _ from "lodash";

import { message } from "antd";
import useOnlineStatus from "../../components/useOnlineStatus";
import NoInternet from "../../components/NoInternet";
import SiteVisit from "../../components/SiteVisit/SiteVisit";
import SiteTabs from "../../components/SiteVisit/SiteTabs";
import SafEarthNavigator from "../../SafEarthNavigator";
import AuctionReRoute from "../auction-reroute";
import EcomReRoute from "../ecom-reroute";

const DashBoard = React.lazy(() => import("../dashboard.route"));

const SCMDashboard = React.lazy(() =>
  import("../../components/scmDashboard/SCMDashboard")
);
const ConstructionDashboard = React.lazy(() =>
  import("../../components/constructionDashboard/ConstructionDashboard")
);

const LoanCentre = React.lazy(() =>
  import("../../components/LoanCentre/LoanCentre")
);

const CustomerLoanCentre = React.lazy(() =>
  import("../../components/customerLoanCentre/CustomerLoanCentre")
);
const CustomerDashboard = React.lazy(() =>
  import("../customer-dashboard.route")
);
const GanttRoute = React.lazy(() => import("../gantt.route"));
const AccountRoute = React.lazy(() => import("../account.route"));
const Login = React.lazy(() => import("../login.route"));
const SignUp = React.lazy(() => import("../signup.route"));
const SignupSplashScreen = React.lazy(() =>
  import("../../components/Auth/SignupSplashScreen")
);


const InventoryPage = React.lazy(() =>
  import("../../components/Inventory/InventoryPage")
);
const AddQuoteModal = React.lazy(() =>
  import("../../components/Auth/AddQuoteModal")
);

const StageItem = React.lazy(() =>
  import("../../components/InnerComponents/StageItem")
);
const ThankYou = React.lazy(() => import("../../components/Auth/ThankYou"));

const DocsRoute = React.lazy(() => import("../docs.route"));
const Photos = React.lazy(() => import("../photos.route"));
const PhotosStageItem = React.lazy(() =>
  import("../../components/InnerComponents/VendorPhotosStage")
);
const PostProjectDetails = React.lazy(() =>
  import("../../components/Projects/PostProjectDetails")
);
const CreateNewProject = React.lazy(() =>
  import("../../components/Projects/CreateNewProject/CreateNewProject")
);
const PrivacyPolicy = React.lazy(() =>
  import("../../components/Privacy-Policy/PrivacyPolicy")
);

const GanttEventRoute = React.lazy(() => import("../gantt-events.route"));
const WorkTableRoute = React.lazy(() => import("../work-table.route"));
const InnerProjectDashboard = React.lazy(() =>
  import("../../components/InnerComponents/InnerProjectDashboard")
);
const ProjectProgress = React.lazy(() =>
  import("../../components/ProjectProgress/ProjectProgress")
);
const NotificationRoute = React.lazy(() => import("../notifictaion.route"));
const ProjectLevelTeamPage = React.lazy(() =>
  import("../../components/InnerComponents/ProjectLevelTeamPage")
);
const MaterialUsedPage = React.lazy(() =>
  import("../../components/InnerComponents/MaterialUsedPage")
);
const ProjectDocs = React.lazy(() =>
  import("../../components/InnerComponents/ProjectDocs")
);
const CommingSoonPage = React.lazy(() =>
  import("../../components/InnerComponents/CommingSoonPage")
);
const ResponsibilityMatrix = React.lazy(() =>
  import("../../components/Projects/ResponsibilityMatrix")
);

const OnboardingSupplierModal = React.lazy(() =>
  import("../../components/Auth/OnboardingSupplierModal")
);
const SampleGantts = React.lazy(() => import("../sample-gantts.route"));
const SampleGanttDetails = React.lazy(() =>
  import("../../components/SampleGantts/SampleGanttDetails")
);
// const ProjectFinanceModule = React.lazy(() =>
//   import("../../components/InnerComponents/ProjectFinanceModule")
// );

const ProjectFinanceModule = React.lazy(() =>
  import("../../components/Finance/FinanceDashboard")
);
const LandingPage = React.lazy(() =>
  import("../../components/LandingPage/LandingPage")
);
const ProjectSpcification = React.lazy(() =>
  import("../../components/InnerComponents/Spcification")
);
const TeamsSubContractors = React.lazy(() =>
  import("../../components/Teams/SwitchTabs")
);
const TeamsSuppliers = React.lazy(() =>
  import("../../components/Suppliers/SuppliersPage")
);
const ProjectReports = React.lazy(() =>
  import("../../components/Reports/ProjectReports")
);
const ProjectPhotos = React.lazy(() =>
  import("../../components/ProjectPhotos/ProjectPhotos")
);
const CRMRoute = React.lazy(() => import("../crm.route"));
const ProjectPlanningRoute = React.lazy(() =>
  import("../project-planning.route")
);
const TicketDashboard = React.lazy(() =>
  import("../../components/InnerComponents/TicketDashboard/TicketDashboard")
);
const EPCsRoute = React.lazy(() => import("../epcs.route"));
const ProjectManagersRoute = React.lazy(() =>
  import("../project-managers.route")
);
const FinancePage = React.lazy(() =>
  import("../../components/LandingPage/Finance/FinancingPage")
);
const RequestFinancingMobile = React.lazy(() =>
  import(
    "../../components/LandingPage/Finance/MobileVersion/RequestFinancingMobile"
  )
);
const FinanceSignUp = React.lazy(() =>
  import(
    "../../components/LandingPage/Finance/MobileVersion/FinanceSignupMobile"
  )
);
const ProposalSignup = React.lazy(() =>
  import("../../components/Auth/ProposalSignup")
);
const Onboarding = React.lazy(() => import("../onboarding.route"));

const EngineeringDashboard = React.lazy(() =>
  import("../../components/engineeringDashboard/EngineeringDashboard")
);
const OnmRoute = React.lazy(() => import("../onm.route"));

const AppRoute = (props) => {
  const query = new URLSearchParams(props?.location?.search);
  const tokenFromURL = query.get("token");
  const pmtToken = query.get("pmtToken");
  const safearthNavigationPath = query.get("path");
  const subContractor = query.get("is_subcontractor");
  const customer = query.get("is_customer");
  const supplier = query.get("is_supplier");
  const rfq = query.get("is_rfq");
  const manufacturerName = query.get("supplier");
  const modelNumber = query.get("model_number");
  const quoteQuantity = query.get("quantity");
  const quotePrice = query.get("price");
  const format = query.get("format");

  const projectIdRedirect = query.get("project");
  const history = useHistory();

  if (subContractor) {
    setItem("subContractor", subContractor);
  }
  if (customer) {
    setItem("customer", customer);
  }
  const isOnline = useOnlineStatus();

  const dispatch = useDispatch();

  if (tokenFromURL && supplier === "true") {
    setItem("onboardSupplier", true);
    setItem("onboardSupplierToken", tokenFromURL);
    history.push("/onboard-suppliers");
    message.info("Please fill all the details!");
  }
  if (tokenFromURL && rfq === "true") {
    setItem("rfq", true);
    setItem("rfqToken", tokenFromURL);
    setItem("quotedItem", {
      manufacturerName,
      quoteQuantity,
      quotePrice,
      modelNumber,
      format,
    });
    history.push("/addQuote");

    message.info("Please fill all the details!");
  }

  if (tokenFromURL && projectIdRedirect) {
    dispatch(
      actionCreators.redirectToProjectDashboard(props, projectIdRedirect)
    );
  }

  if (tokenFromURL && !supplier && !projectIdRedirect) {
    dispatch(actionCreators.onBoardUser(props));
  }

  let secureStorage;
  let projectId;

  if (getItem("userData")) {
    secureStorage = JSON.parse(getItem("userData"));

    if (new Date(secureStorage?.tokenExpirationTime) < new Date()) {
      clearItems();
    }
  }
  if (getItem("projectId")) {
    projectId = JSON.parse(getItem("projectId"));
  }

  const isDraftProject = JSON.parse(getItem("isDraftProject"));

  const getProjectReducer = useSelector((state) => {
    return state.getProjectReducers.projects.get;
  });

  let isCrm =
    props.location.pathname.includes("crm") ||
    getProjectReducer?.success?.data?.is_crm;

  const checkOnline = () => {
    return (
      <Switch>
        <Route path="/no-internet" component={NoInternet} />
        <Redirect from="*" to="/no-internet" />
      </Switch>
    );
  };

  const renderComponents = () => {
    if (!isOnline) {
      return (
        <Switch>
          <Route path="/no-internet" component={NoInternet} />
          <Redirect from="*" to="/no-internet" />
        </Switch>
      );
    }
    if (pmtToken && safearthNavigationPath) {
      return (
        <SafEarthNavigator token={pmtToken} path={safearthNavigationPath} />
      );
    }
    if (JSON.parse(getItem("onboardSupplierToken")) || supplier === "true") {
      if (!isOnline) {
        checkOnline();
      } else {
        return (
          <Switch>
            <Route
              path="/onboard-suppliers"
              component={OnboardingSupplierModal}
            />
            <Route
              exact
              path="/"
              render={() => (
                <LandingPage selectedLogin={"Project Management Tool"} />
              )}
            />
            <Route exact path="/financing" component={FinancePage} />
            <Route
              path="/request-financing"
              component={RequestFinancingMobile}
            />
            <Route exact path="/financing/signup" component={FinanceSignUp} />
            <Redirect from="*" to="/onboard-suppliers" />
          </Switch>
        );
      }
    }
    if (JSON.parse(getItem("rfqToken")) || rfq === "true") {
      if (!isOnline) {
        checkOnline();
      } else {
        return (
          <Switch>
            <Route path="/addQuote" component={AddQuoteModal} />
            <Route path="/thankyoupage" render={() => <ThankYou />} />
            <Route
              exact
              path="/"
              render={() => (
                <LandingPage selectedLogin={"Project Management Tool"} />
              )}
            />
            <Route exact path="/financing" component={FinancePage} />
            <Route
              path="/request-financing"
              component={RequestFinancingMobile}
            />
            <Route exact path="/financing/signup" component={FinanceSignUp} />
            <Redirect from="*" to="/addQuote" />
          </Switch>
        );
      }
    } else if (
      !secureStorage?.authToken ||
      secureStorage?.authToken === null ||
      new Date(secureStorage?.tokenExpirationTime) < new Date()
    ) {
      if (!isOnline) {
        checkOnline();
      } else {
        return (
          <Switch>
            <Route
              exact
              path="/"
              render={() => (
                <LandingPage selectedLogin={"Project Management Tool"} />
              )}
            />
            <Route path="/thankyoupage" component={ThankYou} />
            <Route path="/login" component={Login} />
            <Route exact path="/signup" component={SignUp} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/signup/next" component={SignupSplashScreen} />
            <Route exact path="/financing" component={FinancePage} />
            <Route
              path="/request-financing"
              component={RequestFinancingMobile}
            />
            <Route exact path="/financing/signup" component={FinanceSignUp} />
            <Route exact path="/proposal/signup" component={ProposalSignup} />
            <Route exact path="/onboarding" component={Onboarding} />
            <Redirect from="*" to="/" />
            {/* <Redirect from="*" to="/onboarding" /> */}
          </Switch>
        );
      }
    } else if (secureStorage?.authToken) {
      if (secureStorage?.isConsumer) {
        if (!isOnline) {
          checkOnline();
        } else {
          return (
            <Switch>
              <Route
                exact
                path="/projects/project-gantt"
                component={GanttEventRoute}
              />
              <Route path="/customer-dashboard" component={CustomerDashboard} />
              <Route path="/notifications" component={NotificationRoute} />
              <Route path="/account" component={AccountRoute} />
              <Route
                path="/customer-loan-centre"
                component={CustomerLoanCentre}
              />
              <Route path="/docs" component={DocsRoute} />
              <Route exact path="/stage" component={StageItem} />
              <Route exact path="/" component={LandingPage} />
              <Route path="/ticket-dashboard" component={TicketDashboard} />
              <Route path="/auction" component={AuctionReRoute} />
              <Redirect from="*" to={"/customer-dashboard"} />
            </Switch>
          );
        }
      } else if (
        secureStorage?.isSalesPerson ||
        secureStorage?.department === "Sales"
      ) {
        if (!isOnline) {
          checkOnline();
        } else {
          return (
            <Switch>
              <Route
                exact
                path="/projects/project-dashboard"
                component={InnerProjectDashboard}
              />
              <Route
                exact
                path="/projects/project-gantt"
                component={GanttEventRoute}
              />
              {/* <Route exact path="/projects/photos" component={ProjectDocs} /> */}
              //{" "}
              <Route
                exact
                path="/projects/photos/stage"
                component={PhotosStageItem}
              />
              <Route path="/team" component={TeamsSubContractors} />
              <Route path="/dashboard" component={DashBoard} />
              <Route path="/photos" component={Photos} />
              <Route path="/notifications" component={NotificationRoute} />
              <Route path="/account" component={AccountRoute} />
              <Route exact path="/" component={LandingPage} />
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              {isCrm && <Route path="/crm" component={CRMRoute} />}
              <Route
                exact
                path="/projects/work-table"
                component={WorkTableRoute}
              />
              <Route
                exact
                path="/projects/project-progress"
                component={ProjectProgress}
              />
              <Route
                path="/projects/project-documents"
                component={ProjectDocs}
              />
              <Route path="/stage" component={StageItem} />
              <Redirect
                from="*"
                to={
                  props.location.pathname.includes("/crm")
                    ? "/crm"
                    : projectId
                    ? "/projects/project-dashboard"
                    : "/dashboard"
                }
              />
              <Route exact path="/financing" component={FinancePage} />
              <Route
                path="/request-financing"
                component={RequestFinancingMobile}
              />
              <Route exact path="/financing/signup" component={FinanceSignUp} />
            </Switch>
          );
        }
      } else {
        if (!isOnline) {
          checkOnline();
        } else {
          return (
            <Switch>

              <Route
                exact
                path="/projects/project-dashboard"
                component={InnerProjectDashboard}
              />
              <Route
                exact
                path="/projects/project-gantt"
                component={GanttEventRoute}
              />
              <Route
                exact
                path="/projects/work-table"
                component={WorkTableRoute}
              />
              {!secureStorage?.isProjectManager && (
                <Route
                  path="/projects/project-finance"
                  component={ProjectFinanceModule}
                />
              )}
              <Route
                exact
                path="/projects/project-progress"
                component={ProjectProgress}
              />
              <Route
                exact
                path="/projects/project-reports"
                component={ProjectReports}
              />
              <Route
                path="/projects/project-documents"
                component={ProjectDocs}
              />
              <Route
                path="/projects/engineering-dashboard"
                component={EngineeringDashboard}
              />
              <Route path="/projects/scm-dashboard" component={SCMDashboard} />
              <Route
                path="/projects/construction-dashboard"
                component={ConstructionDashboard}
              />
              {/* <Route path="/projects/photos" component={ProjectPhotos} /> */}
              <Route
                path="/projects/project-team"
                component={ProjectLevelTeamPage}
              />
              <Route
                path="/projects/material-used"
                component={MaterialUsedPage}
              />
              <Route path="/team" component={TeamsSubContractors} />
              <Route path="/suppliers" component={TeamsSuppliers} />
              <Route path="/inventory" component={InventoryPage} />
              <Route path="/gantts" component={GanttRoute} />
              <Route path="/dashboard" component={DashBoard} />
              <Route path="/loan-centre" component={LoanCentre} />
              <Route path="/docs" component={DocsRoute} />
              {/* <Route path="/photos" component={Photos} /> */}
              <Route
                path="/project-specification"
                component={CreateNewProject}
              />
              <Route path="/project-planning" component={PostProjectDetails} />
              <Route path="/notifications" component={NotificationRoute} />
              <Route
                path="/responsibility-matrix"
                component={ResponsibilityMatrix}
              />
              <Route path="/sample-gantts" component={SampleGantts} />
              {/* <Route path="/account/manage-subscription" component={ManageSubscritionRoute} /> */}
              <Route path="/account" component={AccountRoute} />
              <Route
                path="/sample-gantt-details/:gantt_id"
                component={SampleGanttDetails}
              />
              <Route
                path="/projects/specifications"
                component={ProjectSpcification}
              />
              <Route
                path="/projects/project-analytics"
                component={CommingSoonPage}
              />
              <Route exact path="/" component={LandingPage} />
              <Route
                path="/projects/planning"
                component={ProjectPlanningRoute}
              />
              {isCrm && <Route path="/crm" component={CRMRoute} />}
              <Route path="/ticket-dashboard" component={TicketDashboard} />
              <Route path="/stage" component={StageItem} />
              <Route
                path="/projects/photos/stage"
                component={PhotosStageItem}
              />
              <Route path="/sitevisit" component={SiteVisit} />
              <Route path="/sitetabs" component={SiteTabs} />
              <Route
                path="/projects/photos/stage/add"
                render={() => <PhotosStageItem isAddPhoto={true} />}
              />
              {/* <Route path="/signup" component={SignUp} /> */}
              {secureStorage?.isProjectManager && (
                <Route path="/epcs" component={EPCsRoute} />
              )}
              {secureStorage?.isProjectManager && (
                <Route
                  path="/project-managers"
                  component={ProjectManagersRoute}
                />
              )}
              <Route exact path="/financing" component={FinancePage} />
              <Route
                path="/request-financing"
                component={RequestFinancingMobile}
              />
              <Route exact path="/financing/signup" component={FinanceSignUp} />
              <Route exact path="/signup/next" component={SignupSplashScreen} />
              <Route exact path="/onboarding" component={Onboarding} />
              <Route path="/auction" component={AuctionReRoute} />
              <Route path="/ecom" component={EcomReRoute} />
              <Route path="/operations" component={OnmRoute} />
              <Redirect
                from="*"
                to={
                  secureStorage?.isConsumer
                    ? "/customer-dashboard"
                    : projectId
                    ? isDraftProject
                      ? "/dashboard"
                      : "/projects/project-dashboard"
                    : "/dashboard"
                }
              />
            </Switch>
          );
        }
      }
    }
  };

  return (
    <div className="app-route">
      {isOnline ? renderComponents() : checkOnline()}
    </div>
  );
};

export default withRouter(AppRoute);
