import { DownOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Input,
  List,
  message,
  Modal,
  Pagination,
  Row,
  Select,
  Table,
} from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { MagicSpinner } from "react-spinners-kit";
import { getEPCs } from "../../actions/epc-action";
import { getSpoc } from "../../actions/gantt-stage-action";
import {
  addSiteVisit,
  deleteSiteVisit,
  getCompanySpocs,
  getSiteVisit,
  updateSiteVisit,
} from "../../actions/get-site-visit-action";
import { setItem } from "../../SecureStorage";
import Search from "../Search/Search";
import "./site-visit.style.less";
import moment from "moment";
import CreateSiteVisitModal from "./CreateSiteVisitModal";
import _ from "lodash";

const SiteVisit = () => {
  // create site visit modal from
  const [isNewSiteVisit, setIsNewSiteVisit] = useState(false);
  const [editSiteVisitId, setEditSiteVisitId] = useState();
  const [editSiteVisitData, setEditSiteVisitData] = useState({});

  //search bar
  const [searchElement, setSearchElement] = useState("");
  const [searchData, setSearchData] = useState([]);
  //Filter
  const [siteFilterVisible, setSiteFilterVisible] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(getCompanySpocs());
    dispatch(getSiteVisit());
  }, []);

  const getSiteVisitReducer = useSelector((state) => {
    return state.getSiteVisitReducers.siteVisit.get;
  });

  const handleOk = () => {
    setIsNewSiteVisit(false);
  };
  const handleCancel = () => {
    setIsNewSiteVisit(false);
  };

  const searchHandler = (e) => {
    setSearchElement(e.target.value);
  };

  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return (
        <a>
          <LeftOutlined style={{ paddingRight: "5px" }} />
          Previous
        </a>
      );
    }
    if (type === "next") {
      return (
        <a>
          Next
          <RightOutlined style={{ paddingLeft: "5px" }} />
        </a>
      );
    }
    return originalElement;
  }

  const columns = [
    {
      title: "Project Name",
      dataIndex: "projectName",
    },
    {
      title: "Site Visit Date",
      dataIndex: "date",
    },
    {
      title: "Contact Person Name",
      dataIndex: "name",
    },
    {
      title: "Contact Person Number",
      dataIndex: "number",
    },
    {
      title: "My Team SPOC",
      dataIndex: "spoc",
    },

    {
      title: "Location",
      dataIndex: "location",
      width: "200px",
    },
    {
      title: "",
      dataIndex: "edit",
      width: "50px",
    },
    {
      title: "",
      dataIndex: "delete",
    },
    {
      title: "",
      dataIndex: "redirect",
      width: "50px",
    },
  ];

  const siteCreateHandler = () => {
    setEditSiteVisitData({});
    setIsNewSiteVisit(true);
    setEditSiteVisitId();

  };

  const editHandler = (siteVisit) => {
    if (!_.isEmpty(siteVisit)) {
      setIsNewSiteVisit(true);
      setEditSiteVisitId(siteVisit?.id);
      setEditSiteVisitData(siteVisit);
    }
  };

  const deleteHandler = (id) => {
    const formData = new FormData();

    formData.append("site_visit_id", id);
    Promise.resolve(dispatch(deleteSiteVisit(formData))).then((res) => {
      if (res) {
        dispatch(getSiteVisit());
      }
    });
  };

  const mapItems = (i) => {
    return {
      key: i.id,
      projectName: (
        <div>
          <h1>{i.company_name}</h1>
        </div>
      ),
      date: (
        <div>
          <span>{i.site_visit_date}</span>
        </div>
      ),
      name: (
        <div>
          <span>{i.contact_person_name}</span>
        </div>
      ),
      number: (
        <div>
          <span>{i.site_contact}</span>
        </div>
      ),
      spoc: (
        <div>
          <span>{i.spoc}</span>
        </div>
      ),
      spoc_id: i.spoc_id,
      location: (
        <div>
          <span>{i.location}</span>
        </div>
      ),
      edit: (
        <>
          <div onClick={() => editHandler(i)} style={{ cursor: "pointer" }}>
            <img
              src={require("../../assets/logos/Edit.png")}
              style={{ width: "2.5rem", height: "2.5rem" }}
            />
          </div>
        </>
      ),
      delete: (
        <div onClick={() => deleteHandler(i.id)}>
          <Button className="delete-btn">Delete</Button>
        </div>
      ),
      redirect: (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            setItem("siteVisitId", i.id);
            history.push({
              pathname: "/sitetabs",
              siteVisitId: i.id,
            });
          }}
        >
          <RightOutlined />
        </div>
      ),
    };
  };

  const siteVisitData = getSiteVisitReducer.success.data?.site_visits?.map(
    (i) => mapItems(i)
  );

  useEffect(() => {
    if (searchElement) {
      setSearchData(
        getSiteVisitReducer.success.data?.site_visits
          ?.filter((i) => {
            return i.company_name
              .toString()
              .toLowerCase()
              .includes(searchElement.toLowerCase());
          })
          .map((i) => mapItems(i))
      );
    }
  }, [searchElement]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {},
  };
  return (
    <>
      <div className="site-visit-container">
        <CreateSiteVisitModal
          visible={isNewSiteVisit}
          setVisible={setIsNewSiteVisit}
          editSiteVisitId={editSiteVisitId}
          editSiteVisitData={editSiteVisitData}
        />
        {getSiteVisitReducer.loading ? (
          <div
            className="loading"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
            }}
          >
            <MagicSpinner size={100} color="#3d87f1" loading={true} />
          </div>
        ) : getSiteVisitReducer.success.data?.site_visits.length == 0 ? (
          <div className="empty-site-visit-container">
            <img src={require("../../assets/sitevisit/emptyCase.png")} />
            <h1>No Site Visits done yet.</h1>
            <p>Please come back later.</p>
            <Button onClick={() => siteCreateHandler()}>
              START NEW SITE VISIT
            </Button>
          </div>
        ) : (
          <div className="site-visit-list-container">
            <div className="site-visit-list-header">
              <div className="search-site-wrapper">
                <Search
                  onChange={(e) => searchHandler(e)}
                  className="search-site"
                  searchElement={searchElement}
                />

                <Dropdown
                  overlay={
                    <>
                      <h1>Filter</h1>
                    </>
                  }
                  placement="bottomCenter"
                  trigger={["click"]}
                  visible={siteFilterVisible}
                >
                  <div
                    className="filter-button"
                    onClick={() => setSiteFilterVisible(!siteFilterVisible)}
                  >
                    <p className="filter-text">
                      Filter
                      <DownOutlined />
                    </p>
                  </div>
                </Dropdown>
              </div>
              <div className="btns-grp">
                <Button
                  onClick={() => setIsNewSiteVisit(true)}
                  className="generate-btn"
                >
                  Generate Report
                </Button>
                <Button
                  onClick={() => siteCreateHandler()}
                  className="create-site-visit-btn"
                >
                  START NEW SITE VISIT
                </Button>
              </div>
            </div>

            <Table
              className="survey-list-wrapper"
              columns={columns}
              dataSource={searchElement ? searchData : siteVisitData}
              size="middle"
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              scroll={{ y: "300px" }}
              pagination={{
                onChange: (page) => {},
                pageSize: 10,
                itemRender: itemRender,
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default SiteVisit;
